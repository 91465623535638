import React, { useState, useEffect } from 'react'
import { ImgixGatsbyImage } from '@imgix/gatsby'

import { RiCloseLine } from 'react-icons/ri'
import Wrapper from '../components/Wrapper'

const Stills = () => {
  const photoOrder = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12], [13, 14, 15], [16, 17, 18], 
  [19, 20, 21], [22, 23, 24], [25, 26, 27], [28, 29, 30], [31, 32, 33], [34, 35, 36], [37, 38, 39], [40, 41, 42]]

  const [showPicture, setShowPicture] = useState(false)
  const [pictureSrc, setPictureSrc] = useState("")

  useEffect(() => {
    if (!showPicture && pictureSrc) {
      window.scrollTo(0, JSON.parse(localStorage.getItem("scrollTo")))
    }
  }, [showPicture, pictureSrc])

  return (
    <>
      {showPicture ?
        <div className="relative px-4 pt-12">
          <div className="absolute right-2 md:right-16 cursor-pointer text-yellowstone">
            <RiCloseLine size="2em" onClick={() => {setShowPicture(false)}} />
          </div>
          <div className="flex justify-center h-full pt-2 xs:pt-6 sm:mt-20">
            {console.log(pictureSrc)}
            <img
              className="pointer-events-none mt-10 w-[80vw] lg:w-[60vw] xl:w-[40vw]"
              alt=""
              src={pictureSrc}
            />
          </div>
        </div>
      :
        <Wrapper>
          <div className="pt-10 px-5 mx-auto max-w-7xl space-y-2 md:space-y-8 md:px-10">
              { photoOrder.map(triadNum => (
                <div key={`${triadNum[0]}-${triadNum[1]}`} className="flex items-center justify-center space-x-2 md:space-x-4">
                  {triadNum.map(photoNum => (
                    <ImgixGatsbyImage
                      key={`${photoNum}-${photoNum}`}
                      className="cursor-pointer"
                      alt=""
                      src={`https://nicole-mn.imgix.net/stills/${photoNum}.jpg`}
                      layout="constrained"
                      imgixParams={{ width: '400' }}
                      width="400"
                      height="400"
                      onMouseDown={() => { setShowPicture(true); setPictureSrc(`https://nicole-mn.imgix.net/stills/${photoNum}.jpg`); localStorage.setItem("scrollTo", JSON.stringify(window.pageYOffset))}}
                    />
                  ))
                  }
                </div>
              ))}
          </div>
        </Wrapper>
      }
    </>
  )
}

export default Stills